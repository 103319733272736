import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "intro"
    }}>{`Intro`}</h1>
    <p>{`Inventory deals with quotas, a quota is a limit on the number of instances of something that can be reserved at once. A quota will usually have some sort of geographic component, and it will always be associated with a particular departure. For example, one could define a quota on regular tickets for the train leaving Bergen for Oslo at nine o'clock in the morning on the 1st of January 2019. This quickstart will show how to insert your first quota for a fictional transportation company.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`The Inventory requires a Bearer Token in the Authorization header created by the Entur Authentication server for every request.`}</p>
    <h2 {...{
      "id": "creating-departure-information"
    }}>{`Creating departure information`}</h2>
    <p>{`Quotas are added on specific departures identified by their originalDatedServiceJourneyId. A departure is a single service of a train or other vehicle leaving its first passenger-carrying stop at a predetermined time and date. A departure is always associated with a line. A line is an ordered sequence of physical stops that may be visited by a departure, i.e. the geographical path it follows (in Norway this can for instance be Bergensbanen or Dovrebanen). Whether a train actually stops at a given stop on a line is not known by the Inventory module. The Inventory is not the source module for departures and lines, but it needs to have an up-to-date view of both in order to function properly.`}</p>
    <h3 {...{
      "id": "departure-information"
    }}>{`Departure information`}</h3>
    <p>{`Before one can define a quota one needs departure information. A departure needs to know which line it belongs to. All lines are defined in the journey planner, but also have to be defined in the Inventory module, as it is required to be able to calculate available stock between an origin and a destination.`}</p>
    <p>{`The identification used for the line should be the same as in the journey planner module. The identifiers of stops are defined in the National stop register.
Stops are traversed in either the order in which they are defined on the departures line, or in the inverse order depending on the departures invertedDirection-flag being FALSE or TRUE respectively.
In Norway, the (standard) direction of travel is the direction that can be described as outbound from Oslo, and thus, trains travelling from Trondheim to Oslo has invertedDirection=TRUE. This is also reflected in the train/service number; an even number entails invertedDirection=TRUE, while odd numbers entail FALSE.`}</p>
    <h2 {...{
      "id": "creating-a-quota"
    }}>{`Creating a quota`}</h2>
    <h3 {...{
      "id": "inserting-my-first-quota"
    }}>{`Inserting my first quota`}</h3>
    <p>{`In the following section we will create a simple quota with a stoplist, one of the simplest use cases of the inventory. A quota with a stoplist release the resource when a reservation is not using it. If a user reserves the resource in the first half of a journey (e.g. Oslo to Lillestrøm), it is still available for others in the second half (e.g. Lillestrøm to Kløfta). We will now create a quota where the useStoplist-flag is true.`}</p>
    <p>{`In this example we create a quota for the product "ABC:PreassignedFareProduct:Ticket", with 10 available entities. We do not define anything in the "ods", as this is more complex behaviour. You can read more about this in the quides section.`}</p>
    <p>{`Since we won't nest the product with any other products we don't need a quota configuration. The quota needs to have the flag "useStoplist" set as true, this means that you have a limitation of 10 of "ABC:PreassignedFareProduct:Ticket" between each stop in the stoplist. In the real world, this identificator will match the ones setup in the Entur product module. The stoplist used is the one associated with the originalDatedServiceJourneyId "ENT:DatedServiceJourney:2838".`}</p>
    <p><inlineCode parentName="p">{`POST /v1/quotas`}</inlineCode></p>
    <ExpandablePanel title="Example request" style={{
      marginBottom: '1rem'
    }} mdxType="ExpandablePanel">
      <pre>
        {`[
  {
    "quota": 10,
    "products": ["ABC:PreassignedFareProduct:Ticket"],
    "ods": [],
    "useStopList": true,
    "datedServiceJourneyId": "ENT:DatedServiceJourney:2838"
  }
]`}
      </pre>
    </ExpandablePanel>
    <p>{`Congratulations! You have inserted your first quota!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      